import React from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.bubble.css";

interface IProps {
  name: string;
  placeholder: string;
  fallback?: string;
  size?: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: React.FC<IProps> = ({
  name,
  placeholder,
  fallback,
  size,
  value,
  onChange,
}) => {
  const { quill, quillRef } = useQuill({
    theme: "bubble",
    modules: {
      toolbar: [
        [{ header: [2, 3, 4, 5, 6, false] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline"],
        [{ color: [] } /* { background: [] } */],
        ["link", "image"],
        [{ align: [] }],
        ["clean"],
      ],
    },
    placeholder,
  });

  // init
  React.useEffect(() => {
    if (!quill) return;
    quill.clipboard.dangerouslyPasteHTML(value || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!quill) return;
    quill.on("text-change", (delta, oldDelta, source) => {
      const event = {
        target: new EventTarget(),
      } as unknown as React.ChangeEvent<HTMLTextAreaElement>;
      event.target.value = quill.root.innerHTML
        .replace(/(^([ ]*<p><br><\/p>)*)|((<p><br><\/p>)*[ ]*$)/gi, "")
        .trim();
      event.target.name = name;
      onChange(event);
    });
  }, [name, onChange, quill, quillRef]);

  return (
    <div className="flex flex-row items-start justify-center w-full h-full gap-10 group">
      <div
        className={`group-focus-within:visible invisible text-gray-400 w-12 ${size} `}
      >
        {fallback ?? placeholder}
      </div>
      <div
        className={
          "outline-none focus:placeholder:invisible py-2 resize-none w-full h-full" +
          (size ? ` ${size}` : "")
        }
      >
        <div ref={quillRef} />
      </div>

      {/* <textarea
        ref={quillRef}
        onChange={onChange}
        spellCheck="false"
        name={name}
        placeholder={placeholder}
        value={value}
        className={
          "outline-none focus:placeholder:invisible py-2 resize-none w-full h-full" +
          (size ? ` ${size}` : "")
        }
      /> */}
    </div>
  );
};

export default Textarea;
