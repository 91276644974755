import { doc, setDoc, Timestamp } from "@firebase/firestore";
import React from "react";
import { IState as ICreateState } from "../pages/Create";
import { db } from "../config/firebaseConfig";
import { useNavigate } from "react-router-dom";
interface IProps {
  note: ICreateState["note"];
}

const Button: React.FC<IProps> = ({ note }) => {
  const navigate = useNavigate();

  const handlePublish = async (): Promise<any> => {
    // generate random string
    const id = Math.random().toString(36).slice(2, 9);
    const slug = note.title.toLowerCase().replace(/\s/g, "-") + "-" + id;
    const data = {
      ...note,
      author: note.author || "anonymous",
      content: note.content.replace(/\n/g, "<br />"),
      createdAt: Timestamp.now(),
    };

    await setDoc(doc(db, "notes", slug), data);
    navigate("/", { replace: true });
  };

  const [disabled, setDisabled] = React.useState(true);

  React.useEffect(() => {
    const { title = "", content = "" } = note || {};
    const value = title.trim().length === 0 || content.trim().length === 0;
    setDisabled(value);
  }, [note]);

  return (
    <button
      disabled={disabled}
      type="button"
      className={
        "absolute px-8 py-2 font-bold uppercase  rounded-full top-10 right-10 " +
        (disabled
          ? " disabled text-gray-300 bg-white-300 border-2 border-grey"
          : " text-black  transition duration-300 border-2  border-black hover:bg-black hover:text-white")
      }
      onClick={handlePublish}
    >
      发布
    </button>
  );
};

export default Button;
